import {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useRouter } from 'next/router';
import moment from 'moment';
import { Button, Space } from '@arkestro/arkestro-design-system';
import { DeleteOutlined } from '@ant-design/icons';
import useTranslation from 'next-translate/useTranslation';
import { useFlags } from 'launchdarkly-react-client-sdk';

import { NotificationsContext } from '@components/providers/NotificationsProvider/NotificationsProvider';
import { UserContext } from '@components/providers/UserProvider/UserProvider';
import NotificationsListItemIcon
  from '@components/layout/Header/Notifications/NotificationsListItemIcon/NotificationsListItemIcon';
import {
  Notification,
  NotificationActionsMap,
} from '@components/layout/Header/Notifications/types';
import { determineAction } from '@components/layout/Header/Notifications/utils';
import { GeneralContext } from '@components/providers/GeneralProvider/GeneralProvider';
import { markSeen } from '@utils/StreamNotificationUtils';

import style from './NotificationsListItem.module.scss';

type Props = {
  item: Notification;
  allList: Notification[];
  setAllList: Dispatch<SetStateAction<Notification[]>>;
};

const NotificationsListItem = ({
  item,
  allList,
  setAllList,
}: Props): JSX.Element | null => {
  const { t } = useTranslation('common');
  const router = useRouter();
  const user = useContext(UserContext);
  const generalContext = useContext(GeneralContext);
  const { streamNotificationsFeed: feed, setProperty } =
    useContext(NotificationsContext);
  const [isNew, setIsNew] = useState(false);
  const flags = useFlags();

  useEffect(() => {
    setIsNew(!item?.is_seen);
  }, [item?.is_seen]);

  if (!item?.created_at) return null;

  const formattedTimeStamp = moment.utc(item.created_at).local();

  const actions = Object.keys(item.actions_object).map((key: string) => {
    const rawActionData =
      item?.actions_object[key as keyof NotificationActionsMap];

    const data: { [key: string]: any } = {
      type: key,
      ...rawActionData,
      title: rawActionData?.title.toLowerCase(),
      onClick: determineAction(key),
    };

    if (['accepted_company_invite', 'accepted_request_invite'].includes(key)) {
      data['answer'] = 'accepted';
    } else if (
      ['rejected_company_invite', 'rejected_request_invite'].includes(key)
    ) {
      data['answer'] = 'rejected';
    }

    return data;
  });

  const markNotificationAsSeen = () => {
    // Send to the backend that this notification is seen
    markSeen(feed, [item.activity_id as string]);
    // Set the current view to designate that it is seen
    setIsNew(false);
    // update total count of unseen
    const newList = allList.filter(
      (notify: Notification) =>
        !notify.is_seen && item.activity_id !== notify.activity_id
    );

    setProperty({
      unseenCount: newList.length,
    });
  };

  return (
    <div className={style.listItem} data-testid='notifications-list-item'>
      <div className={style.icon} aria-hidden='true'>
        <NotificationsListItemIcon iconType={item.action_name} />
      </div>
      <div className={style.listItemContent}>
        <div className={style.header}>
          <h3
            className={style.title}
            data-testid='notifications-list-item-title'
          >
            {isNew && <span className={style.newDot} />}
            {item.title}
          </h3>
          <time
            className={isNew ? style.timestampNew : style.timestamp}
            dateTime={formattedTimeStamp.toISOString()}
            title={formattedTimeStamp.toLocaleString()}
            data-testid='notifications-list-item-timestamp'
          >
            {formattedTimeStamp.fromNow()}
          </time>
        </div>
        <div
          className={style.content}
          data-testid='notifications-list-item-description'
        >
          {item.description}
        </div>
        <div
          className={style.footer}
          data-testid='notifications-list-item-actions'
        >
          <Space>
            {actions.length > 0 &&
              actions.map(
                action =>
                  action.type !== 'cancel' && (
                    <Button
                      key={`notification-action-${action.type}`}
                      type='link'
                      onClick={() =>
                        action.onClick({
                          feed,
                          item,
                          user,
                          allList,
                          setAllList,
                          router,
                          answer: action.answer,
                          generalContext,
                          flags,
                        })
                      }
                    >
                      {action.title}
                    </Button>
                  )
              )}
            {isNew &&
              !['company_invitation', 'request_invitation'].includes(
                item.action_name
              ) && (
              <Button
                type='link'
                onClick={() => markNotificationAsSeen()}
              >
                {t('notifications.mark_as_read')}
              </Button>
            )}
          </Space>

          {actions.length > 0 &&
            actions.map(
              action =>
                action.type === 'cancel' && (
                  <Button
                    key={`notification-action-${action.type}`}
                    data-testid='notifications-list-item-dismiss'
                    type='text'
                    title={action.title}
                    onClick={() =>
                      action.onClick({
                        feed,
                        item,
                        user,
                        allList,
                        setAllList,
                        router,
                        answer: action.answer,
                        generalContext,
                        flags,
                      })
                    }
                    icon={<DeleteOutlined />}
                  />
                )
            )}
        </div>
      </div>
    </div>
  );
};

export default NotificationsListItem;
