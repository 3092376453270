import PropTypes from 'prop-types';
import { useContext, useEffect } from 'react';
import { Layout } from 'antd-v3';
import { useRouter } from 'next/router';
import { combineClassNames } from '@arkestro/arkestro-design-system';
import Routes from '@services/Routes';
import { LayoutContext } from '@providers/LayoutProvider/LayoutProvider';
import IntercomService from '@services/IntercomService';
import SegmentService from '@services/SegmentService';
import { UserContext } from '@components/providers/UserProvider/UserProvider';
import ErrorBoundary from 'components/shared/ErrorBoundaries/ErrorBoundary';
import Header from 'components/layout/Header/Header';
import SuperAdminBar from 'components/layout/SuperAdminBar/SuperAdminBar';
import style from './Content.module.scss';

const Content = ({ children }) => {
  const router = useRouter();
  const isNewRequestPage = router.asPath.includes(Routes.APP_ROUTES.NEW_REQUEST);
  const user = useContext(UserContext);
  const { collapsed } = useContext(LayoutContext);
  const sidebar = collapsed ? style.sidebarCollapsed : style.sidebarExpanded;
  const contentBodyClassName = combineClassNames(style.container, sidebar);

  useEffect(() => {
    if (!user.id) return;

    SegmentService.configureUser({ currentUser: user });
  },[user.id]);

  // Dependency on Business Region so that if it changes, we reconfigure Intercom
  // the correct region.
  useEffect(() => {
    if (!user.id) return;
    IntercomService.configureUser({ currentUser: user, collapsed });
  },[user.id, user.business_region]);

  return (
    <Layout className={collapsed ? 'collapsed' : ''}>
      {isNewRequestPage && <SuperAdminBar />}
      {!isNewRequestPage && <Header />}
      <Layout.Content>
        <ErrorBoundary>
          <div id='content-body' data-testid='content-body' className={contentBodyClassName}>
            {children}
          </div>
        </ErrorBoundary>
      </Layout.Content>
    </Layout>
  );
};

Content.propTypes = {
  children: PropTypes.node.isRequired
};

export default Content;
