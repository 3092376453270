import { WarningOutlined } from '@ant-design/icons';
import * as Sentry from '@sentry/nextjs';
import { isDev } from '@utils/constants';
import PropTypes from 'prop-types';
import { Component } from 'react';

const PANEL_STYLE = {
  padding: 15,
  textAlign: 'center',
  borderRadius: 5
};

const ICON_STYLE = {
  color: '#777',
  fontSize: 14,
  marginRight: 8
};

const MESSAGE_STYLE = {
  fontSize: 12,
  color: '#777'
};

class ErrorBoundary extends Component {
  constructor (props) {
    super(props);

    this.state = {
      hasError: false
    };
  }

  static getDerivedStateFromError () {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch (error, info) {
    this.setState({ hasError: true, error, info });

    if (!isDev) {
      const sentryInstance = Sentry.getCurrentHub();

      sentryInstance.withScope(scope => {
        scope.setExtras(info);
        sentryInstance.captureException(error);
      });
    }
  }

  render () {
    const { border, message, marginBottom, style } = this.props;
    const { error } = this.state;
    const borderStyle = border ? { border: '1px solid #ccc' } : {};

    if (this.state.hasError) {
      return (
        <div style={{ ...PANEL_STYLE, ...borderStyle, marginBottom, ...style }}>
          <WarningOutlined style={ICON_STYLE} />
          {error && (
            <div style={MESSAGE_STYLE}>
              {message}
            </div>
          )}
        </div>
      );
    }

    return this.props.children;
  }
}

ErrorBoundary.propTypes = {
  border: PropTypes.bool,
  children: PropTypes.node.isRequired,
  message: PropTypes.string,
  style: PropTypes.shape(),
  marginBottom: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string
  ])
};

ErrorBoundary.defaultProps = {
  style: {},
  border: false,
  message: 'There was a problem loading this information. Please try again.',
  marginBottom: 15
};

export default ErrorBoundary;
