import * as Sentry from '@sentry/nextjs';
import { isDev } from '@utils/constants';
import { Link } from 'next/link';
import PropTypes from 'prop-types';
import { Component } from 'react';

const errorLine = line => {
  const lineString = line.toString();

  return (<div><br/><span>&nbsp;&nbsp;{lineString}</span></div>);
};

const getErrorLinesHtml = lineList => (
  lineList.split(/\n/g).map(line => errorLine(line))
);

class ErrorBoundary extends Component {
  constructor (props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch (error, info) {
    // Display fallback UI
    this.setState({ hasError: true, error, info });

    if (!isDev) {
      const sentryInstance = Sentry.getCurrentHub();

      sentryInstance.withScope(scope => {
        scope.setExtras(info);
        sentryInstance.captureException(error);
      });
    }
  }

  render () {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      if (isDev) {
        return (
          <div style={{ height: '100%' }}>
            <h1 style={{
              fontSize: '5rem',
              marginTop: '1em'
            }}
            >Something went wrong.</h1>
            <span>&nbsp;&nbsp;In Development Mode</span><br/>
            <span>&nbsp;&nbsp;Error Stack</span><br/>
            {getErrorLinesHtml(this.state.error.stack)}
            <br/><br/>
            <span>&nbsp;&nbsp;Error Info</span><br/>
            {getErrorLinesHtml(this.state.info.componentStack)}
          </div>
        );
      }

      return (
        <div style={{ height: '100%' }}>
          <h1 style={{
            fontSize: '5rem',
            marginTop: '1em'
          }}
          >Something went wrong.</h1>
          <p>This may be because you are using Internet Explorer. If you are using Internet Explorer, please use Edge,
            Chrome, or Firefox for a better experience.</p>
          <p> Please contact support@arkestro.com for further assistance. To reload the application, try refreshing your
            browser or <Link href="/"><a>Click Here</a></Link> to return to the home page of the app</p>
        </div>
      );
    }

    return this.props.children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.node.isRequired
};

export default ErrorBoundary;
