import { utc } from 'moment';
import { Company, User } from 'types';

const getUserType = (category?: string, company?: Company) => {
  // TODO: This feels uncomfortable, we should do better later
  let companyId = 'superadmin';
  if (category === 'buyer' && company && company?.id) {
    companyId = company?.id;
  }
  else if (category === 'vendor') {
    companyId = 'vendor';
  }

  return companyId;
};

// DataDog User Data
// In alignment with SegmentService for sending user info:
// We do not care about sending the role, isSelfServe, isDemoAccount, planType, timestamp
export const buildDatadogUserTrackingObject = (user: Partial<User>) =>({
  email: user.email,
  id: user.id,
  name: `${user.first_name} ${user.last_name}`,
  is_superadmin_session: `${user.impersonating}`,
  superadmin_user: user.true_user,
  organization_id: user.organization_id || 0,
  organization_name: user.organization_name || 'Arkestro System',
  company_id: getUserType(user.category, user.company),
  category: user.category,
});

// Segment User Data
export const buildSegmentUserTrackingObject = (user: Partial<User>) => {
  const trackedUser = user.impersonating ? user.true_user || {} : user;

  return {
    email: trackedUser.email,
    isSuperadminSession: user.impersonating,
    superadminUser: user.impersonating ? user.true_user?.email : '',
    organizationId: trackedUser.organization_id || 0,
    organizationName: trackedUser.organization_name || 'Arkestro System',
    companyId: getUserType(trackedUser.category, trackedUser.company),
    userType: trackedUser.category,
    role: trackedUser.role,
    isSelfServe: trackedUser.is_self_serve || false,
    isDemoAccount: trackedUser.is_demo_account || false,
    planType: trackedUser.planType,
    timestamp: utc(),
  };
};
