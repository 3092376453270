import useTranslation from 'next-translate/useTranslation';
import { Modal, Typography } from '@arkestro/arkestro-design-system';
import { DeleteOutlined } from '@ant-design/icons';

type Props = {
  visible?: boolean;
  title?: string;
  subtitle?: string;
  confirmText?: string;
  declineText?: string;
  onConfirm: () => void;
  onCancel: () => void;
};

const OnBlurModal = ({
  visible = false,
  title,
  subtitle,
  confirmText,
  declineText,
  onConfirm,
  onCancel,
}: Props): JSX.Element => {
  const { t } = useTranslation('common');

  return (
    <Modal
      data-testid='on-blur-modal'
      title={title?.trim()?.length ? title : t('unsaved_changes_confirmation.title')}
      open={visible}
      onOk={onConfirm}
      onCancel={onCancel}
      okText={confirmText?.trim()?.length ? confirmText : t('unsaved_changes_confirmation.confirm_text')}
      cancelText={declineText?.trim()?.length ? declineText : t('unsaved_changes_confirmation.decline_text')}
      okButtonProps={{ icon: <DeleteOutlined /> }}
      okType='danger'
    >
      <Typography.Paragraph>
        {subtitle?.trim()?.length ? subtitle : t('unsaved_changes_confirmation.subtitle')}
      </Typography.Paragraph>
    </Modal>
  );
};

export default OnBlurModal;
