import _ from 'lodash';
import QueryString from 'query-string';
import axios from 'axios';
import { matchesNew } from 'utils/strings';
import { MULTIPART_FORM_DATA_HEADER, validateFileType } from '@utils/FileType';
import { Call, ApiUrl, HttpErrorMessage, ParseGeneralResponse } from './ApiUtils';

const addExpandOptionsToString = options => {
  let expands = '';

  if (options.scoringSurvey) expands += '&expand[]=scoring_survey';

  if (options.hasSurvey) expands += '&expand[]=has_survey';
  if (options.hasScoringSurvey) expands += '&expand[]=has_scoring_survey';
  if (options.hasTcoSurvey) expands += '&expand[]=has_tco_survey';

  if (options.hasSurveyResponses) expands += '&expand[]=has_survey_responses';
  if (options.hasTcoSurveyResponses) expands += '&expand[]=has_tco_survey_responses';
  if (options.hasScoringSurveyResponses) expands += '&expand[]=has_scoring_survey_responses';

  if (options.responseCount) expands += '&expand[]=response_count';
  if (options.draftResponseCount) expands += '&expand[]=draft_response_count';
  if (options.lineItemsCount) expands += '&expand[]=line_items_count';

  if (options.documents) expands += '&expand[]=vendor_documents&expand[]=bid_documents';

  // TLC: PEPPERMINT-642?
  if (options.lots) expands += '&expand[]=lots';

  return expands;
};

// TLC: PEPPERMINT-642(FETCH): 5.1 CCHs
// TLC: PEPPERMINT-642(FETCH): 5.1.1 CCHs
// TLC: PEPPERMINT-642(FETCH): 5.1.2 CCHs
// TLC: PEPPERMINT-642(FETCH): 5.1.3 CCHs
// TLC: PEPPERMINT-642(FETCH): 5.1.4 CCHs (LEGACY)
export function fetchRequest (id, additionalParams) {
  const url = ApiUrl(`/bid_requests/${id}?${additionalParams}`);

  return Call(
    'get',
    url,
    {},
    ParseGeneralResponse,
    {
      responseDataKeys:
        {
          bid_request: 'bid_request',
          supplierPrequalLineItemQuestionCount: 'supplier_line_item_prequal_question_count'
        },
    },
  );
}

export function fetchRequestChat (id) {
  const url = ApiUrl(`/bid_request_chat/${id}`);

  return Call(
    'get',
    url,
    {},
    ParseGeneralResponse,
    {
      responseDataKeys:
        {
          bid_request: 'bid_request_chat',
        },
    },
  );
}

export const getRequestUsers = (id, category = '') => {
  const url = ApiUrl(`/bid_requests/${id}/users?category=${category}`);

  return Call('get', url, {}, ParseGeneralResponse, {
    responseDataKeys: { users: 'users' },
  });
};

export function saveBidRequest (data) {
  // TLC: PEPPERMINT-642(SAVE)?
  const url = ApiUrl(`/bid_requests/${data.requestId}`);
  const method = 'put';

  return Call(
    method,
    url,
    data,
    ParseGeneralResponse,
    { responseDataKeys: { bid_request: 'bid_request' }, return: { } }
  );
}

export const scheduleBidRequest = data => Call(
  'post',
  ApiUrl(`/bid_requests/${data.id}/schedule`),
  data,
  ParseGeneralResponse,
  {
    responseDataKeys: {
      bid_request: 'bid_request'
    }
  }
);

// Supplier Documents
export const saveBidDocument = async (t, binaryTypesAllowed, data) => {
  const url = ApiUrl(`/bid_requests/${data.requestId}/add_document`);
  const formData = new FormData();

  if (data.file) formData.append('file', data.file);

  formData.append('name', data.name);
  formData.append('description', data.description);
  formData.append('submission_required', data.submission_required);
  formData.append('approval_required', data.approval_required);
  formData.append('will_accept_submissions', data.will_accept_submissions);
  formData.append('blocks_access', data.blocks_access);
  formData.append('consent_required', data.consent_required);

  try {
    await validateFileType({ t, file: data.file, binaryTypesAllowed });

    const response = await axios.post(url, formData, { headers: MULTIPART_FORM_DATA_HEADER });

    return ParseGeneralResponse(true, response, {
      responseDataKeys: {
        bid_documents: 'bid_documents'
      }
    });
  } catch (error) {
    return ParseGeneralResponse(false, error, {});
  }
};

export const saveBuyerDocument = async (t, binaryTypesAllowed, data) => {
  const url = ApiUrl(`/bid_requests/${data.id}/add_buyer_document`);

  const formData = new FormData();

  formData.append('file', data.file);

  try {
    await validateFileType({ t, file: data.file, binaryTypesAllowed });

    const response = await axios.put(url, formData, { headers: MULTIPART_FORM_DATA_HEADER });

    return ParseGeneralResponse(true, response, { responseDataKeys: { buyer_documents: 'buyer_documents' } });
  } catch (error) {
    return ParseGeneralResponse(false, error, {});
  }
};

export const submitVendorDocument = async (t, binaryTypesAllowed, file, requestId, documentId) => {
  const url = ApiUrl(`/bid_responses/submit_document?id=${requestId}&document_id=${documentId}`);

  const formData = new FormData();

  formData.append('file', file);
  try {
    await validateFileType({ t, file, binaryTypesAllowed });

    const response = await axios.put(url, formData, { headers: MULTIPART_FORM_DATA_HEADER });

    const responseDataKeys = {
      document: 'document',
      missing_required_documents: 'missing_required_documents'
    };

    return ParseGeneralResponse(true, response, { responseDataKeys });
  } catch (error) {
    return ParseGeneralResponse(false, error, {});
  }
};

export const updateVendorDocument = async (t, binaryTypesAllowed, requestId, documentId, attributes) => {
  const url = ApiUrl(`/bid_requests/${requestId}/vendor_documents/${documentId}`);
  const formData = new FormData();

  formData.append('approved', attributes.approved);
  formData.append('rejection_reason', attributes.rejectionReason);

  try {
    await validateFileType({ t, file: attributes.approved, binaryTypesAllowed });

    const response = await axios.put(url, formData, { headers: MULTIPART_FORM_DATA_HEADER });

    return ParseGeneralResponse(
      true,
      response,
      { responseDataKeys: { bid_documents: 'bid_documents' } }
    );
  } catch (error) {
    return ParseGeneralResponse(false, error, {});
  }
};

export function deleteBuyerDocument (requestId, documentId) {
  const url = ApiUrl(`/bid_requests/${requestId}/delete_buyer_document/`);
  const method = 'put';

  return Call(
    method,
    url,
    { document_id: documentId },
    ParseGeneralResponse,
    {
      responseDataKeys: { documents: 'documents', errors: 'errors' },

    }
  );

}

export function deleteBidDocument (requestId, documentId) {
  const url = ApiUrl(`/bid_requests/${requestId}/delete_document/`);
  const method = 'put';

  return Call(
    method,
    url,
    { document_id: documentId },
    ParseGeneralResponse,
    {
      responseDataKeys: { documents: 'documents', errors: 'errors' },

    }
  );

}

export function changeDocumentSubmissionRequired (requestId, documentId) {
  const url = ApiUrl(`/bid_requests/${requestId}/document_submission_required/`);
  const method = 'put';

  return Call(
    method,
    url,
    { document_id: documentId },
    ParseGeneralResponse,
    {
      responseDataKeys: { documents: 'documents', errors: 'errors' },

    }
  );

}

export function cloneBidRequest (id) {
  const url = ApiUrl(`/bid_requests/${id}/clone?set_prices_from_awarded=true`);
  const method = 'post';

  return Call(
    method,
    url,
    {},
    ParseGeneralResponse,
    {
      responseDataKeys: {},

    }
  );
}

export function repairBidRequest (id, mechanicTask) {
  const url = ApiUrl(`/bid_requests/${id}/repair`);
  const method = 'post';

  return Call(
    method,
    url,
    {
      mechanic_task: mechanicTask
    },
    ParseGeneralResponse,
    {
      responseDataKeys: {
        message: 'message'
      },
    }
  );
}

export function submitDeleteEntireEvent (id) {
  const url = ApiUrl(`/bid_requests/${id}/delete`);
  const method = 'post';

  return Call(
    method,
    url,
    {},
    ParseGeneralResponse,
    {
      responseDataKeys: { errors: 'errors' },
    }
  );
}

// TLC: PEPPERMINT-642(IMPORT): API
export function saveRequestLot (
  lotIndex,
  requestId,
  lot,
  hideBaselinePrice = undefined,
  lot_level_only_callbacks = false,
  fetch = false,
  forceQueue = false,
  importedCustomHeaders = [],
  questionsToClone = [],
  // TLC: PEPPERMINT-642(IMPORT): API
  customColumnHeadersToClone = []
) {
  // TLC: PEPPERMINT-642(IMPORT): API
  let url = ApiUrl('/line_items/lot_update');

  let method = 'put';

  // TLC: PEPPERMINT-642(IMPORT): API
  if (matchesNew(lot.id)) {
    url = ApiUrl('/line_items/lot_create');
    method = 'post';
  }
  return Call(
    method,
    url,
    {
      bid_request_id: requestId,
      id: lot.id,
      sno: lot.sno,
      name: lot.name,
      line_items: lot.line_items,
      associated_criteria_ids: lot.associated_criteria_ids,
      description: lot.description,
      incumbent_id: lot.incumbent_id,
      hide_baseline_price: hideBaselinePrice,
      // TLC: PEPPERMINT-642(IMPORT): API: CCHs
      custom_column_headers: lot.custom_column_headers,
      lot_level_only_callbacks,
      fetch,
      force_queue: forceQueue,
      // TLC: PEPPERMINT-642(IMPORT): API: CCHs
      imported_custom_headers: importedCustomHeaders,
      questions_to_clone: questionsToClone,
      // TLC: PEPPERMINT-642(IMPORT): API: CCHs
      custom_column_headers_to_clone: customColumnHeadersToClone
    },
    ParseGeneralResponse,
    {
      responseDataKeys: {
        bidLot: 'lot',
        lineItemErrors: 'line_item_errors',
        status: 'status',
        // TLC: PEPPERMINT-642(IMPORT): API: CCHs
        company_custom_headers: 'company_custom_headers'
      },
      return: { lotIndex },
    },
  );
}

export function deleteRequestLot (lotId, lotIndex) {
  const url = ApiUrl('/line_items/lot_destroy');

  return Call(
    'delete',
    url,
    {
      id: lotId,
    },
    ParseGeneralResponse,
    {
      return: { lotIndex },
    },
  );
}

// TLC: PEPPERMINT-642(DELETE)? CCHs
export function deleteAllLots (requestId) {
  const url = ApiUrl('/line_items/remove_all_lots');

  return Call(
    'delete',
    url,
    {
      bid_request_id: requestId,
    },
    ParseGeneralResponse,
    {
      responseDataKeys: {
        status: 'status',
        deleted_qual_questions: 'deleted_qual_questions',
        // TLC: PEPPERMINT-642(DELETE)? CCHs
        deleted_custom_column_headers: 'deleted_custom_column_headers'
      },
    },
  );
}

export function ParseSaveRequestUserResponse (success, response, options) {
  const result = { success };

  if (!_.isEmpty(options.return)) {
    result.return = options.return;
  }
  if (success) {
    const dataKeys = options.responseDataKeys || {};

    _.forEach(dataKeys, (value, key) => {
      const valueKey = _.isEmpty(value) ? 'data' : `data.${value}`;

      result[key] = _.get(response, valueKey, null);
    });
  } else {
    result.errors = HttpErrorMessage(response);
    result.users = _.get(response, 'response.data.users', []);
  }
  return result;
}

export function fetchBidRequestsMinimalList () {
  const url = ApiUrl('/request_list');

  return Call(
    'get',
    url,
    {},
    ParseGeneralResponse,
    {
      responseDataKeys:
        {
          bidRequests: 'bid_requests'
        },
    },
  );
}

export function fetchBidRequestWithResponsesAndVendorDocuments (id, options = {}) {
  let expands = 'expand[]=lots_count&expand[]=vendors';

  expands += '&expand[]=vendor_documents&expand[]=document_responses';
  expands += '&expand[]=bid_documents&expand[]=response_count&expand[]=bid_acceptance';
  expands += addExpandOptionsToString(options);

  const additional = '&additional[]=responses';
  const url = ApiUrl(`/bid_requests/${id}?${expands}${additional}`);

  return Call(
    'get',
    url,
    {},
    ParseGeneralResponse,
    {
      responseDataKeys:
        {
          bid_request: 'bid_request',
          responses: 'responses',
        },
    },
  );
}

export function fetchBidRequestWithDocuments (id, options = {}) {
  const expands = addExpandOptionsToString(options);
  const query = 'expand[]=vendor_documents&expand[]=vendors&expand[]=bid_documents';
  const url = ApiUrl(`/bid_requests/${id}?${query}${expands}`);

  return Call(
    'get',
    url,
    {},
    ParseGeneralResponse,
    {
      responseDataKeys: { bid_request: 'bid_request' },
    }
  );
}

export function fetchResponsesWithResponseLots (id) {
  const url = ApiUrl('/bid_responses/show_responses?expand=[]response_lots');

  return Call(
    'post',
    url,
    { bid_request_id: id },
    ParseGeneralResponse,
    {
      responseDataKeys:
      {
        responses: 'responses'
      },
    },

  );
}

// TLC(FETCH): Check how data is loaded to add the Total Landed Cost.
// TLC: PEPPERMINT-642(FETCH): 5.1.5 CCHs
export function fetchVendorBid (options) {
  const { requestId, questions, vendorOrgId, bidAcceptance, lineItemPrequal, specialTemplateUrl } = options;

  // TLC: PEPPERMINT-642(FETCH): 5.1.5 CCHs
  let url = ApiUrl(`/bid_requests/${requestId}`);

  // Some items could be made conditional for performance when appropriate?
  // Be mindful of the question mark for beginning query string if we condition.
  url += '?expand[]=anchor_price_line_items&additional[]=anchor_pricing_disabled';
  url += '&expand[]=line_items_count&expand[]=lots_count';
  url += '&active_bid=true&additional[]=draft_bid';

  if (questions) {
    url += '&additional[]=all_questions_answered&expand[]=has_line_item_prequal_survey';
    url += '&expand[]=all_survey_questions&expand[]=include_lot_dupes';
  }

  // Used when previewing lots & line items as a specific vendor org
  if (vendorOrgId) url += `&vendor_org_id=${vendorOrgId}`;
  if (bidAcceptance) url += '&additional[]=bid_acceptance';
  if (lineItemPrequal) url += '&additional[]=line_item_prequal';
  if (specialTemplateUrl) url += '&expand[]=special_template_url';

  url += addExpandOptionsToString(options);

  return Call(
    'get',
    url,
    {},
    ParseGeneralResponse,
    {
      responseDataKeys:
        {
          bid_request: 'bid_request',
          bid: 'response',
          draftBid: 'draft_bid',
          allQuestionsAnswered: 'all_questions_answered',
          bidAcceptance: 'bid_acceptance',
          supplierPrequalLineItemAnswers: 'supplier_line_item_prequal_answers',
          supplierLineItemAnswerCount: 'supplier_line_item_answer_count',
          supplierPrequalLineItemQuestionCount: 'supplier_line_item_prequal_question_count',
          supplierOrganizationId: 'supplier_organization_id'
        },
    },
  );
}

export function getVendorEventGroups (requestId) {
  const url = ApiUrl(`/bid_requests/${requestId}/vendor_event_groups`);

  return Call(
    'get',
    url,
    {},
    ParseGeneralResponse,
    {
      responseDataKeys:
        {
          vendor_event_groups: 'vendor_event_groups'
        },
    },
  );
}

export function fetchBidTimings (requestId) {
  const url = ApiUrl(`/bid_requests/${requestId}/bid_timings`);

  return Call(
    'get',
    url,
    {},
    ParseGeneralResponse,
    {
      responseDataKeys:
        {
          data: 'data',
          responses: 'responses',
          line_item_round_ranks: 'line_item_round_ranks',
          lot_round_ranks: 'lot_round_ranks'
        },
    },
  );
}

export function fetchLowestOverallResponseLotTotals (requestId) {
  const url = ApiUrl(`/bid_requests/${requestId}/lowest_overall_response_lot_totals`);

  return Call(
    'get',
    url,
    {},
    ParseGeneralResponse,
    {
      responseDataKeys:
        {
          lot_min_totals: 'lot_min_totals'
        },
    },
  );
}

export function updateBidAcceptance (requestId, answer) {
  const url = ApiUrl(`/bid_requests/${requestId}/update_vendor_acceptance`);

  return Call(
    'PUT',
    url,
    {
      answer,
    },
    ParseGeneralResponse,
    {
      responseDataKeys:
        {
          bid_acceptance: 'bid_acceptance',
        },
    },
  );
}

export function updateUiConfig (requestId, key, value) {
  const url = ApiUrl(`/bid_requests/${requestId}/update_ui_config`);

  return Call(
    'put',
    url,
    {
      key,
      value
    },
    ParseGeneralResponse,
    {
      responseDataKeys:
        {
          ui_config: 'ui_config',
        },
    },
  );
}

export function removeUser (requestId, userIds) {
  const url = ApiUrl(`/bid_requests/${requestId}/remove_user`);
  const method = 'put';

  return Call(
    method,
    url,
    { user_ids: userIds },
    ParseGeneralResponse,
    {
      responseDataKeys: { users: 'users', errors: 'errors', vendorGroup: 'vendor_event_group' },

    }
  );
}

export function fetchVendorShortlistForRequest (id, params = {}) {
  const stringParams = QueryString.stringify(params);
  const url = ApiUrl(`/bid_requests/${id}/request_vendor_shortlist?${stringParams}`);

  return Call(
    'get',
    url,
    {},
    ParseGeneralResponse,
    {
      responseDataKeys:
        {
          records: 'records',
          counts: 'counts',
          pagy: 'pagy',
          shortlisted_emails : 'shortlisted_emails',
          existing_supplier_emails: 'existing_supplier_emails'
        },
    },

  );
}

export function fetchVendorOrgsForRequest (id) {
  const url = ApiUrl(`/bid_requests/${id}/vendor_organizations`);

  return Call(
    'get',
    url,
    {},
    ParseGeneralResponse,
    {
      responseDataKeys:
      {
        vendor_organizations: 'vendor_organizations',
        overall_incumbent: 'overall_incumbent'
      }
    }
  );
}

export function fetchAllEmailsForRequest (id, filter, inviteStatusExceptions) {
  let url = ApiUrl(`/bid_requests/${id}/all_emails`);

  url += `?filter=${filter}`;
  if (inviteStatusExceptions) url += `&invite_status_exceptions=${inviteStatusExceptions}`;

  return Call(
    'get',
    url,
    { },
    ParseGeneralResponse,
    {
      responseDataKeys:
        {
          emails: 'emails'
        },
    },

  );
}

export function fetchAllShortlistedEmailsForRequest (id, inviteStatusExceptions) {
  let url = ApiUrl(`/bid_requests/${id}/all_shortlisted_emails`);

  if (inviteStatusExceptions) url += `?invite_status_exceptions=${inviteStatusExceptions}`;

  return Call(
    'get',
    url,
    {},
    ParseGeneralResponse,
    {
      responseDataKeys:
        {
          emails: 'emails'
        },
    },

  );
}

export function fetchRecentVendorOrgs (requestId, limit) {
  let url = ApiUrl(`/bid_requests/${requestId}/recent_vendor_organizations`);

  if (limit) url += `?limit=${limit}`;

  return Call(
    'get',
    url,
    {},
    ParseGeneralResponse,
    {
      responseDataKeys:
        {
          recent_vendor_organizations: 'recent_vendor_organizations'
        },
    },
  );
}

export function addVendorProfilesToShortlistForRequest (id, data) {
  const url = ApiUrl(`/bid_requests/${id}/add_vendors_to_shortlist`);

  return Call(
    'put',
    url,
    {
      email_list: data.emailList,
      vendor_profile_ids: data.vendorProfileIds,
      recommended_emails: data.recommended
    },
    ParseGeneralResponse,
    {
      responseDataKeys:
        {
          vendor_profiles: 'vendor_profiles',
          emails_skipped: 'emails_skipped',
        },
    }
  );
}

export function addVendorOrganizationsToShortlistForRequest (id, data) {
  const url = ApiUrl(`/bid_requests/${id}/add_vendor_orgs_to_shortlist`);

  return Call(
    'put',
    url,
    {
      vendor_organization_ids: data.vendorOrganizationIds
    },
    ParseGeneralResponse,
    {
      responseDataKeys:
        {
          vendor_profile: 'vendor_profile',
        },
    }
  );
}

export function deleteVendorProfilesFromShortlistForRequest (id, vendorProfileIds) {
  const url = ApiUrl(`/bid_requests/${id}/delete_vendors_from_shortlist`);

  return Call(
    'delete',
    url,
    {
      vendor_profile_ids: vendorProfileIds
    },
    ParseGeneralResponse,
    {
      responseDataKeys:
        {
          deleted: 'deleted',
        },
    }
  );
}

export function deleteVendorOrganizationsFromShortlistForRequest (id, vendorOrganizationIds) {
  const url = ApiUrl(`/bid_requests/${id}/delete_orgs_from_shortlist`);

  return Call(
    'delete',
    url,
    {
      vendor_organization_ids: vendorOrganizationIds
    },
    ParseGeneralResponse,
    {
      responseDataKeys:
        {
          deleted: 'deleted',
        },
    }
  );
}

export const inviteUsers = async (requestId, emails, options = {}, shouldAttachTable = false) => Call(
  'post',
  ApiUrl(`/bid_requests/${requestId}/invite`),
  {
    id: requestId,
    emails,
    ...options, // camelCase options won't map to Rails, so add them below
    is_test: options.isTest,
    as_collaborator: options.asCollaborator,
    file_attachment: shouldAttachTable
  },
  ParseGeneralResponse,
  {
    responseDataKeys: { sent_emails: 'sent_emails',
      unsent_emails: 'unsent_emails' }
  }
);

export const inviteAndAssociateSuppliers = async (requestId, emails, options = {}, shouldAttachTable = false) => Call(
  'post',
  ApiUrl(`/bid_requests/${requestId}/invite_and_associate_suppliers`),
  {
    id: requestId,
    emails,
    ...options,
    is_test: options.isTest,
    as_collaborator: false,
    file_attachment: shouldAttachTable
  },
  ParseGeneralResponse,
  {
    responseDataKeys: { sent_emails: 'sent_emails',
      unsent_emails: 'unsent_emails' }
  }
);

export const processStreamUserTimes = (requestId, userIdTimeMaps) => (
  Call(
    'put',
    ApiUrl(`/bid_requests/${requestId}/process_stream_engagement_measures`),
    {
      message_user_ids_times: userIdTimeMaps,
    },
    ParseGeneralResponse,
    {
      success: 'success'
    }
  )
);

export const updateOverallIncumbent = (requestId, orgId) => {
  const url = ApiUrl('/line_items/set_overall_incumbent');

  return Call(
    'put',
    url,
    {
      bid_request_id: requestId,
      vendor_organization_id: orgId
    },
    ParseGeneralResponse,
    {}
  );
};

export const deleteLineItemPreQualSurvey = requestId => (
  Call(
    'delete',
    ApiUrl(`/bid_requests/${requestId}/line_item_pre_qualification_survey/`),
    {},
    ParseGeneralResponse,
    {
      responseDataKeys: {
        line_item_pre_qual_total_questions_count: 'line_item_pre_qual_total_questions_count',
        line_item_pre_qual_answers_submitted_count: 'line_item_pre_qual_answers_submitted_count',
        deleted: 'deleted'
      }
    }
  )
);

export const createSupplierSurvey = (requestId, data) => (
  Call(
    'post',
    ApiUrl(`/bid_requests/${requestId}/supplier_survey`),
    data,
    ParseGeneralResponse,
    {
      responseDataKeys: {
        survey: 'survey'
      }
    }
  )
);

/**
 * Fetches the survey for the bid reqest
 * @param {Number} requestId Id of the bid request
 * @returns {Promise | Promise<unknown>}
 */
export const fetchSupplierSurvey = requestId => (
  Call(
    'get',
    ApiUrl(`/bid_requests/${requestId}/supplier_survey`),
    {},
    ParseGeneralResponse,
    {
      responseDataKeys: {
        survey: 'survey'
      },
    }
  )
);

export function fetchResponseSummary (responseId) {
  const url = ApiUrl(`/bid_responses/${responseId}/list_summary`);

  return Call(
    'get',
    url,
    {},
    ParseGeneralResponse,
    {
      responseDataKeys:
      {
        responseSummary: 'response_summary'
      },
    },

  );
};

export function fetchResponseSummaries (responseId) {
  const url = ApiUrl(`/bid_responses/${responseId}/list_summaries`);

  return Call(
    'get',
    url,
    {},
    ParseGeneralResponse,
    {
      responseDataKeys:
      {
        responseSummaries: 'response_summaries'
      },
    },

  );
};
