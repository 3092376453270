import { ReactElement, ReactNode, useContext, useState, useEffect } from 'react';
import { NextPage } from 'next';
import { AppProps } from 'next/app';
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import localeData from 'dayjs/plugin/localeData';
import weekday from 'dayjs/plugin/weekday';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import weekYear from 'dayjs/plugin/weekYear';
import { ApolloProvider, ApolloConsumer } from '@apollo/react-hooks';
import { useLDClient } from 'launchdarkly-react-client-sdk';
import ApolloService from 'services/ApolloService';
import ConversationsProvider from 'components/providers/ConversationsProvider/ConversationsProvider';
import GeneralProvider from 'components/providers/GeneralProvider/GeneralProvider';
import Head from 'components/shared/Head/Head';
import Layout from 'components/layout/layout';
import { LayoutProvider } from '@components/providers/LayoutProvider/LayoutProvider';
import { UserContext } from 'components/providers/UserProvider/UserProvider';
import LaunchDarklyService from '@services/LaunchDarklyService';
import { BreadCrumbsProvider } from '@components/providers/BreadCrumbsProvider/BreadCrumbsProvider';
import Onboarding from './Onboarding';

// https://stackoverflow.com/questions/76650410/antd-dayjs-typeerror-clone-weekday-is-not-a-function
dayjs.extend(customParseFormat);
dayjs.extend(advancedFormat);
dayjs.extend(weekday);
dayjs.extend(localeData);
dayjs.extend(weekOfYear);
dayjs.extend(weekYear);

// https://nextjs.org/docs/pages/building-your-application/routing/pages-and-layouts#with-typescript
export type NextPageWithLayout<P = {}, IP = P> = NextPage<P, IP> & {
  getLayout?: (_page: ReactElement) => ReactNode
};

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout
};

const App = ({ Component, pageProps }: AppPropsWithLayout) => {
  const user = useContext(UserContext);
  const ldClient = useLDClient();
  const [apolloClient, setApolloClient] = useState(null);
  const { category, on_boarding: onboarding } = user;

  useEffect(() => {
    if (!user.id) return () => {};

    const apollo = new ApolloService(user);
    const client = apollo.initialize();

    setApolloClient(client);
  }, [user.id]);

  useEffect(() => {
    if (!user.id || !ldClient) return;

    // We are not using the async load pattern with LD, so we need to ensure we actually have
    // the client before we try to configure the user.
    LaunchDarklyService.configureUser(ldClient, user);
  }, [user.id, !!ldClient]);

  // Authentication pages and public pages
  if (Component.ignoreAuthorization) return <Component {...pageProps} />;

  // Placing something here other than `null` would be like our existing Arkestro GIF.
  if (!user.id || !apolloClient) return null;

  // Pages that should not include the SideMenu & Header Layout Template
  if (Component.ignoreLayout) return <Component {...pageProps} />;

  // Use the layout defined at the page level, if available
  const getLayout = Component.getLayout ?? ((page: any) => page);
  const componentInLayout = getLayout(<Component {...pageProps} />);

  if (onboarding !== 'completed' && category !== 'superadmin') return <Onboarding />;

  return (
    <ApolloProvider client={apolloClient}>
      <Head />
      <ApolloConsumer>
        {gqlClient => (
          <GeneralProvider gqlClient={gqlClient}>
            <LayoutProvider>
              <ConversationsProvider gqlClient={gqlClient}>
                <BreadCrumbsProvider>
                  <Layout>
                    {componentInLayout}
                  </Layout>
                </BreadCrumbsProvider>
              </ConversationsProvider>
            </LayoutProvider>
          </GeneralProvider>
        )}
      </ApolloConsumer>
    </ApolloProvider>
  );
};

export default App;
