export const {
  ANALYZE, // process.env.ANALYZE === 'true'
  DD_TRACE_ENABLED,
  LOG_LEVEL,
  NEXT_PUBLIC_ACTIVE_STORAGE_S3_DOMAIN,
  NEXT_PUBLIC_ARKESTRO_ENV,
  NEXT_PUBLIC_CLIENT_DOMAIN,
  NEXT_PUBLIC_DAPI_URL,
  NEXT_PUBLIC_DD_RUM_APPLICATION_ID,
  NEXT_PUBLIC_DD_RUM_CLIENT_TOKEN,
  NEXT_PUBLIC_DD_VERSION,
  NEXT_PUBLIC_HANDSONTABLE_LICENSE_KEY,
  NEXT_PUBLIC_HELP_PAGE_DOMAIN,
  NEXT_PUBLIC_HELP_PAGE_URL,
  NEXT_PUBLIC_INTERCOM_APP_ID,
  NEXT_PUBLIC_INTERCOM_EU_APP_ID,
  NEXT_PUBLIC_NODE_ENV,
  NEXT_PUBLIC_PLATFORM_DOMAIN,
  NEXT_PUBLIC_PUSHER_CLUSTER,
  NEXT_PUBLIC_PUSHER_KEY,
  NEXT_PUBLIC_SEGMENT_KEY,
  NEXT_PUBLIC_SENTRY_DSN,
  NEXT_PUBLIC_STREAM_KEY,
  NEXT_PUBLIC_TINYMCE_API_KEY,
  NEXT_PUBLIC_VERB_API_KEY,
  NEXT_PUBLIC_WS_HOST,
  NODE_ENV,
  SENTRY_DSN,
  USE_INTERCOM_INTEGRATION
} = process.env;

export const FLAT_FILE_LICENSE = process.env.FLAT_FILE_LICENSE || '206652fe-cdc0-4801-8743-c5e24aa80686';
export const LINE_ITEM_IMPORT_LIMIT = process.env.LINE_ITEM_IMPORT_LIMIT || 500;
export const INVITE_BATCH_LIMIT = Number.parseInt(process.env.NEXT_PUBLIC_INVITE_BATCH_LIMIT, 10) || 100;
// developers may want their own dev id -- edit this string but don't check in to GitHub
export const NEXT_PUBLIC_LAUNCH_DARKLY_ID = process.env.NEXT_PUBLIC_LAUNCH_DARKLY_ID || '5db074bef559b808a6d1e37f';
export const NEXT_PUBLIC_NODE_URL = process.env.NEXT_PUBLIC_NODE_URL || 'localhost';
export const NEXT_PUBLIC_ONESCHEMA_CLIENT_ID = process.env.NEXT_PUBLIC_ONESCHEMA_CLIENT_ID || '';
export const NEXT_PUBLIC_ONE_SCHEMA_IMPORTER_CLIENT_ID = process.env.NEXT_PUBLIC_ONE_SCHEMA_IMPORTER_CLIENT_ID || '7e930ec4-a1dd-415e-be10-27b7566fa284';
export const NEXT_PUBLIC_RECAPTCHA_SITE_KEY = process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY || '123';
export const NEXT_PUBLIC_STREAM_NOTIFICATIONS_APPID = process.env.NEXT_PUBLIC_STREAM_NOTIFICATIONS_APPID || '62183';
export const NEXT_PUBLIC_STREAM_NOTIFICATIONS_KEY = process.env.NEXT_PUBLIC_STREAM_NOTIFICATIONS_KEY || 'zsq32mmzdbnt';
export const NEXT_PUBLIC_VERB_STATIC_AUTH_COMPANY_IDS = process.env.NEXT_PUBLIC_VERB_STATIC_AUTH_COMPANY_IDS || '';
export const NEXT_PUBLIC_VERB_VERSION = process.env.NEXT_PUBLIC_VERB_VERSION || 'v1.0';
export const isDev = NODE_ENV !== 'production'; // && NEXT_PUBLIC_NODE_ENV !== 'production' && NEXT_PUBLIC_ARKESTRO_ENV !== 'prod';
export const initialIsHuman = NODE_ENV === 'test' || false;

export const VALID_ENVIRONMENTS = ['dev', 'stage', 'sandbox', 'prod'];

export const WEB_SOCKET_HOST = NEXT_PUBLIC_WS_HOST || 'ws://localhost:3002';

export const PUBLIC_BUCKET_URL = 'https://bidops-public.s3.amazonaws.com';
export const ASSETS_URL = `${PUBLIC_BUCKET_URL}/assets`;
export const IMAGES_URL = `${ASSETS_URL}/images`;
export const LOGOS_URL = `${ASSETS_URL}/logos`;

export const FULL_LOGO_BLACK_URL = `${LOGOS_URL}/logo@2x.png`;
export const FULL_LOGO_WHITE_URL = `${LOGOS_URL}/logo_white@2x.png`;

export const MARKETING_SITE_URL = 'https://arkestro.com';
export const USER_AVATAR_URL = `${IMAGES_URL}/user_avatar.svg`;

export const PRIVACY_POLICY_URL = 'https://arkestro.com/privacy-policy';
export const TERMS_URL = 'https://arkestro.com/terms-of-use';

export const AVAILABLE_FILE_EXT_ICONS = ['doc', 'xlsx', 'csv', 'txt', 'pdf'];

export const REQUEST_SORTING_DATE_FIELDS = [
  'bidding_events.start_at',
  'bidding_events.end_at',
  'bid_requests.start_time',
  'bid_requests.end_time',
  'bid_requests.open_for_viewing_at',
];

export const getPinoConfig = () => {
  const pinoConfig = {
    level: LOG_LEVEL || 'warn'
  };

  if (NODE_ENV !== 'production') {
    pinoConfig.transport = {
      target: 'pino-pretty'
    };
  }

  // TODO: do we need to align with bidops-node here? this is what's missing
  // if (NODE_ENV === 'production') {
  //   pinoConfig.formatters = {
  //     level: label => ({ level: label })
  //   };
  // }

  return pinoConfig;
};
