import { useContext } from 'react';
import { Layout, Affix } from 'antd-v3';
import { combineClassNames } from '@arkestro/arkestro-design-system';
import { UserContext } from '@components/providers/UserProvider/UserProvider';
import LogoImage from 'components/LogoImage/LogoImage';
import DeeplinkLogin from 'components/Login/DeeplinkLoginDisplay/DeeplinkLogin';
import SuperAdminBar from 'components/layout/SuperAdminBar/SuperAdminBar';
import { LayoutContext } from 'components/providers/LayoutProvider/LayoutProvider';
import SettingsDisplay from './SettingsDisplay/SettingsDisplay';
import Collapser from './Collapser/Collapser';
import style from './Header.module.scss';

const Header = () => {
  const user = useContext(UserContext);
  const isDeeplink = user.is_deeplink;
  const { collapsed, toggleSideMenu } = useContext(LayoutContext);
  const sidebar = collapsed ? style.sidebarCollapsed : style.sidebarExpanded;
  const containerClassName = combineClassNames(style.container, sidebar);

  return (

    <Affix offsetTop={0}>
      <div>
        <SuperAdminBar />
        <Layout.Header id='nav-header' data-testid='layout-header' className={containerClassName}>
          <div className={style.leftColumn}>
            <Collapser toggleSideMenu={toggleSideMenu} />
            <LogoImage
              imageStyle={{ maxWidth: 150, maxHeight: 65 }}
              useDark
              useMyCompanyLogo
              fallbackToBidops={collapsed}
            />
          </div>
          {isDeeplink ? <DeeplinkLogin /> : <SettingsDisplay />}
        </Layout.Header>
      </div>
    </Affix>

  );};

export default Header;
