import {
  NEXT_PUBLIC_LAUNCH_DARKLY_ID,
  NEXT_PUBLIC_NODE_URL,
  NEXT_PUBLIC_STREAM_KEY,
  NEXT_PUBLIC_STREAM_NOTIFICATIONS_APPID,
  NEXT_PUBLIC_STREAM_NOTIFICATIONS_KEY
} from '@utils/constants';

const config = {
  nodeUrl: NEXT_PUBLIC_NODE_URL,
  perPage: 20,
  superAdminDateTimeFormat: 'DD MMM, YYYY hh:mm A', // This is NOT to be used in company or request context!
  streamKey: NEXT_PUBLIC_STREAM_KEY,
  launchDarklyClientSideID: NEXT_PUBLIC_LAUNCH_DARKLY_ID,
  streamNotificationsKey: NEXT_PUBLIC_STREAM_NOTIFICATIONS_KEY,
  streamNotificationsAppID: NEXT_PUBLIC_STREAM_NOTIFICATIONS_APPID,
};

export default config;
