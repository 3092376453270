import { useContext, useEffect } from 'react';
import Link from 'next/link';
import useTranslation from 'next-translate/useTranslation';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useRouter } from 'next/router';
import { Menu, Layout } from 'antd-v3';
import { Tooltip } from '@arkestro/arkestro-design-system';
import { CloseOutlined, QuestionCircleFilled } from '@ant-design/icons';

import { UserContext } from '@providers/UserProvider/UserProvider';
import { LayoutContext } from '@providers/LayoutProvider/LayoutProvider';
import { ConversationsContext } from '@providers/ConversationsProvider/ConversationsProvider';
import { GeneralContext } from '@providers/GeneralProvider/GeneralProvider';
import { collapsedIntercomLeft, expandedIntercomLeft } from '@services/IntercomService';

import Logo from './Logo';
import style from './SideMenu.module.scss';
import Footer from './Footer';
import Backdrop from './Backdrop';
import { getSideMenuItems } from './utils';

export const SIDER_MIN_WIDTH = 80; // set by AntD
export const SIDER_MAX_WIDTH = 240;

const activeClass = (path, router) => {
  const decodePaths = window.decodeURIComponent(router.asPath).split('/');
  const paths = path.split('/');

  if (paths.length > decodePaths.length) return '';
  for (let index = 1; index < paths.length; index += 1) {
    if (decodePaths[index] !== paths[index]) return '';
  }
  return 'active';
};

/**
 * LaunchDarkly Flags: showVerbReports,
 * enableEventHealthDashboard, enableEventEffortSavingsDashboard,
 * enableExecutiveSavingsDashboard, useNewSupportSite, showInsightsMenuOption
 */
const SideMenu = () => {
  const router = useRouter();
  const conversationsContext = useContext(ConversationsContext);
  const { unreadCount } = conversationsContext;
  const { t } = useTranslation('common');
  const user = useContext(UserContext);
  const generalContext = useContext(GeneralContext);
  const { toggleSideMenu, collapsed } = useContext(LayoutContext);
  const { isAutomated } = generalContext;
  let tabIndex = 0;

  // TODO: Update showVerbReports flag or remove when we rip out Verb Data.
  // showVerbReports must be true for Sigma to work.
  const {
    showVerbReports: showVerbOrSigmaReports,
    enableExecutiveSavingsDashboard,
    enableEventHealthDashboard = false,
    enableEventEffortSavingsDashboard = false,
    useNewSupportSite,
    showInsightsMenuOption,
    enableAnomalousEventsTaggingForAwardingReports = false,
    useLogisticsMarketReport = false
  } = useFlags();
  const getTabIndex = () => {
    tabIndex += 1;
    return tabIndex;
  };

  const menuItems = getSideMenuItems({
    t,
    user,
    router,
    unreadCount,
    showVerbOrSigmaReports,
    enableExecutiveSavingsDashboard,
    enableEventHealthDashboard,
    enableEventEffortSavingsDashboard,
    useLogisticsMarketReport,
    isAutomated,
    showInsightsMenuOption,
    enableAnomalousEventsTaggingForAwardingReports,
  });

  let helpUrl = 'https://help.arkestro.com';

  let helpText = t('general.help_articles');

  if (useNewSupportSite) {
    helpUrl = '/help';
    helpText = t('general.help');
  }

  // Verb Reports require a fresh page load to ensure correct styles applied.
  const renderAnchorItem = item => (
    <a
      data-testid='anchor-item'
      href={item.requirePageLoad ? item.path : undefined}
      className={activeClass(item.path, router)}
      onClick={item.onClick}
    >
      {item.icon && item.icon}
      <span>{item.text}</span>
    </a>
  );

  const getLink = item => {
    if (item.requirePageLoad || item.onClick) return renderAnchorItem(item);

    return (
      <Link
        href={item.path}
        onClick={item.onClick}
        target={item.target}
        tabIndex={getTabIndex()}
        legacyBehavior
      >
        {renderAnchorItem(item)}
      </Link>
    );
  };

  const getIntercomPadding = () => {
    const pixels = collapsed ? collapsedIntercomLeft : expandedIntercomLeft;

    return `${pixels.toString()}px`;
  };

  // When toggling the side menu we need to update the location of the intercom launcher
  useEffect(() => {
    const intercomLauncher = document.querySelector('.intercom-lightweight-app-launcher');

    if (intercomLauncher) {
      intercomLauncher.style.left = getIntercomPadding();
    }
  }, [collapsed]);

  return (
    <div id='side-menu' className={`side-menu ${style.wrapper}`}>
      <Layout.Sider
        id='sider'
        width={SIDER_MAX_WIDTH}
        breakpoint='sm'
        collapsed={collapsed}
        trigger={null}
        collapsible
      >
        <Logo collapsed={collapsed} />
        <CloseOutlined onClick={toggleSideMenu} className={style.closeBtn} />
        <Menu mode='inline' selectable={false} inlineIndent={4}>
          {menuItems.map(item => {
            if (!item.visible) return null;

            return (
              <Menu.Item key={`${item.text}:${item.path}`} title={item.title || item.text}>
                {item.text === t('side_menu.request_overview') && (
                  <Tooltip title={t('tooltip.general.home')} placement="right">
                    {getLink(item)}
                  </Tooltip>
                )}
                {item.text !== t('side_menu.request_overview') && getLink(item)}
              </Menu.Item>
            );
          })}
          <Menu.Item key='help-item' title={helpText}>
            <a
              href={helpUrl}
              target='_blank'
              rel='noopener noreferrer'
              tabIndex={getTabIndex()}
            >
              <QuestionCircleFilled />
              <span>{helpText}</span>
            </a>
          </Menu.Item>
        </Menu>
        <Footer collapsed={collapsed} />
      </Layout.Sider>
      <Backdrop collapsed={collapsed} />
    </div>
  );
};

export default SideMenu;
