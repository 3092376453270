import { Badge } from '@arkestro/arkestro-design-system';

import { MailFilled } from '@ant-design/icons';
import useTranslation from 'next-translate/useTranslation';

interface Props {
  unreadCount: number;
}

export const CommCenterIcon = ({ unreadCount }: Props) => {
  const { t } = useTranslation('common');

  return (
    <Badge
      className='badge-count'
      count={unreadCount}
      offset={unreadCount > 10 ? [10, 0] : [0, 0]}
      overflowCount={99}
    >
      <MailFilled />
      <span className='link-text'>{t('general.messages')}</span>
    </Badge>
  );
};
