import { isDev } from '@utils/constants';
import { alertMessage } from 'utils/common';
import Routes from './Routes';

const LOCKED_CODE = 423;
const UNAUTHORIZED_CODE = 401;
const FORBIDDEN_CODE = 403;

// Add a path here to ignore being redirected to login page when not authorized
const AUTHENTICATION_PATH_MAP = {
  [Routes.APP_ROUTES.LOGIN_PAGE]: true,
  [Routes.APP_ROUTES.FORGOT_PASSWORD]: true,
  [Routes.APP_ROUTES.RESET_PASSWORD]: true,
  [Routes.APP_ROUTES.CREATE_ACCOUNT]: true,
  [Routes.APP_ROUTES.SSO_LOGIN_PAGE]: true,
  [Routes.APP_ROUTES.SSO_AUTH_PATH]: true,
  [Routes.APP_ROUTES.SSO_AUTH_CALLBACK_PATH]: true,
  [Routes.APP_ROUTES.SSO_AUTH_FAILURE_PATH]: true,
  [Routes.APP_ROUTES.SIDEKIQ_PATH]: isDev,
};

// Remove param strings and numbers from path and rejoin (e.g. exclude ':param' from string)
const joinPath = path => (
  path.split('/')
    .filter(i => !!i && !i.includes(':') && !Number.parseInt(i, 10))
    .join('/')
);

class RedirectService {
  static redirectToLogin = router => {
    if (router) {
      router.replace(Routes.APP_ROUTES.LOGIN_PAGE);
      return;
    }

    if (typeof window === 'undefined') return;

    window.location = Routes.APP_ROUTES.LOGIN_PAGE;
  }

  static redirectAuthedUser = ({ user, router }) => {
    if (!user) return;

    const {
      is_super_admin: isSuper,
      impersonating: isImpersonating,
      is_deeplink: isDeeplink
    } = user;
    const isPublicPage = RedirectService.isPublicPage(router);
    const isOnAuthenticationPage = RedirectService.isAuthenticationPage(router);
    const isOnPlatformAdminPage = RedirectService.isPlatformAdministrationPage(router);

    // Don't do any redirect on a public page.
    if (isPublicPage) return;

    if (isDeeplink) {
      // NEXT_DEEPLINK
      // Don't do anything currently. What do we need to check with a deeplink user?
      // Probably, look at their contextable id and confirm they are on the right page?
      return;
    }

    if (!isSuper && (isOnAuthenticationPage || isOnPlatformAdminPage)) {
      if (isOnPlatformAdminPage && !isImpersonating) alertMessage('You cannot access this page.', 'error');

      RedirectService.redirectToRoot(router);
      return;
    }

    RedirectService.redirectSuperAdmin({ user, router });
  }

  static redirectUserToRoot = (user, router) => {
    if (!user) return;
    if (user.is_super_admin) {
      alertMessage('Redirected to Platform Administration', 'warning');
      RedirectService.redirectToSuperRoot(router);
    } else {
      alertMessage('You cannot access this page.', 'error');
      RedirectService.redirectToRoot(router);
    }
  }

  static redirectToRoot = router => {
    router.replace(Routes.ROOT_AUTHENTICATED_PATH);
  }

  static redirectToSuperRoot = router => {
    router.replace(Routes.SUPER_ROOT_PATH);
  }

  static redirectSuperAdmin = ({ user, router }) => {
    if (!user || !user.is_super_admin) return;

    // User just signed in and needs to be redirected to a root page
    if (RedirectService.isAuthenticationPage(router)) {
      if (user.is_super_admin) {
        RedirectService.redirectToSuperRoot(router);
      } else {
        RedirectService.redirectToRoot(router);
      }

      return;
    }

    // Routing to a standard, non-authentication page route
    const hasBidRequestsSegment = router.asPath.includes('/bid_requests');
    const hasAdministrationSegment = router.asPath.includes('/platform_administration');

    // Super trying to access bid requests and not on the correct platform administrations page
    if (!hasBidRequestsSegment || hasAdministrationSegment) return;

    RedirectService.redirectToSuperRoot(router);
    alertMessage('Redirected to Platform Administration', 'warning');
  }

  static isAuthenticationPage = router => {
    if (typeof window === 'undefined') return false;
    if (RedirectService.isDeeplinkPage(router)) return true;

    let path = window.location.pathname;

    if (router) path = router.asPath;

    // Splitting and grabbing the first to handle slugs in url.
    const firstLevelPath = `/${path.split('/')[1]}`;

    return !!AUTHENTICATION_PATH_MAP[firstLevelPath];
  }

  static isPublicPage = router => {
    if (typeof window === 'undefined') return false;

    let path = window.location.pathname;

    if (router) path = router.asPath;

    const matchedPath = joinPath(path);

    return Routes.PUBLIC_PAGE_ROUTES.some(r => joinPath(r).includes(matchedPath));
  }

  static isDeeplinkPage = router => {
    if (typeof window === 'undefined') return false;

    let path = window.location.pathname;

    if (router) path = router.asPath;

    return path.includes('/deeplink/');
  }

  static isPlatformAdministrationPage = router => (
    router.asPath.includes('/platform_administration')
  )

  static wasUnauthorizedError = error => [UNAUTHORIZED_CODE, FORBIDDEN_CODE].includes(error.response.status);

  static wasLockedError = error => [LOCKED_CODE].includes(error?.response?.status);

  static get UNAUTHORIZED_CODE () { return UNAUTHORIZED_CODE; }

  static get FORBIDDEN_CODE () { return FORBIDDEN_CODE; }

  static get LOCKED_CODE () { return LOCKED_CODE; }
}

export default RedirectService;
