import PropTypes from 'prop-types';
import { Component, createContext } from 'react';
import { attachTrackingAnalytics } from '@services/SegmentService';
import { SENT_MESSAGE, CONVERSATION_ID } from 'utils/analytics_constants';
import { UserContext } from 'components/providers/UserProvider/UserProvider';
import { setupStream } from 'services/StreamService';
import { getChannelIdsWithUnread } from 'utils/chat';

const DEFAULT_OPTIONS = {
  activeChannel: undefined,
  newActiveChannel: undefined,
  showNewChannelModal: false,
  showSettingsMenu: false, // settings menu in chat channel
  streamChatToken: undefined,
  streamClient: undefined,
  unreadChannelMap: {},
  unreadCount: undefined,
};

export const ConversationsContext = createContext({
  ...DEFAULT_OPTIONS,
  setProperty: () => {}
});

class ConversationsProvider extends Component {
  constructor (props) {
    super(props);

    this.state = {
      ...DEFAULT_OPTIONS,
      gqlClient: props.gqlClient
    };

    this.setProperty = this.setProperty.bind(this);
  }

  async componentDidMount () {
    // We want give stream a second to do it's thing to avoid multiple rerenders of child components that
    // use streamClient object. This only happens on initial page load/mount.
    const streamClient = await setupStream(this, this.props.gqlClient, this.props.user);

    if (this.props.user.subdomain === 'synthetics') return;

    // Get all unread channels and set to state initially then use streamClient listener
    getChannelIdsWithUnread(streamClient, this.props.user)
      .then(({ unreadChannelMapFromFetch,totalUnreadMessagesCount }) => {
        this.setState({
          unreadChannelMap: unreadChannelMapFromFetch,
          unreadCount: totalUnreadMessagesCount,
        }, () => {
          // React batches setState during multiple event calls, so we need to update the unreadCount outside
          // the event block because some events return undefined for total_unread_count.

          // Anytime a Stream event is fired, update unread channels but don't fetch new data. Just listen.
          // Pay attention to CID names that are prepended with "messaging:" but channel object IDs do not
          // prepend and instead have a "type" property.
          streamClient.on(({
            type,
            user: eventUser,
            cid,
            channel_id:channelId,
            unread_messages: unreadMessages,
            total_unread_count: totalUnreadCount
          }) => {
            const isNewMessage = (type === 'message.new' || type === 'notification.message_new');
            const isMe =  eventUser?.id === this.props.user.id.toString();
            const isRead = type === 'notification.mark_read';
            const isMarkUnread = type === 'notification.mark_unread';

            if (!isNewMessage && !isRead && !isMarkUnread) return; // don't care about health checks

            // I wrote the message so don't update anything
            if (isNewMessage && isMe) {
              if (cid) attachTrackingAnalytics(SENT_MESSAGE, { [CONVERSATION_ID]: cid });
              return;
            }

            if (isRead && !isMe) return; // don't care about others reading their own messages

            // Handle batching issue with setState
            // https://reactjs.org/docs/react-component.html#setstate
            this.setState(state => {
              const { unreadChannelMap } = state;
              const newUnreadCount = isNewMessage ? (unreadChannelMap[channelId] || 0)  + 1 : unreadMessages;

              return {
                unreadChannelMap: {
                  ...unreadChannelMap,
                  [channelId]: newUnreadCount
                },
                unreadCount: totalUnreadCount
              };
            });
          });
        });
      });
  }

  setProperty (property) {
    this.setState(property);
  }

  render () {
    return (
      <ConversationsContext.Provider
        value={{
          ...this.state,
          setProperty: this.setProperty
        }}
      >
        {this.props.children}
      </ConversationsContext.Provider>
    );
  }
}

ConversationsProvider.propTypes = {
  children: PropTypes.node.isRequired,
  gqlClient: PropTypes.shape().isRequired,
  user: PropTypes.shape().isRequired
};

const exportedComponent = props => (
  <UserContext.Consumer>
    {user => <ConversationsProvider {...props} user={user} />}
  </UserContext.Consumer>
);

export default exportedComponent;
