import { datadogRum } from '@datadog/browser-rum';
import {
  NEXT_PUBLIC_ARKESTRO_ENV,
  NEXT_PUBLIC_DD_RUM_APPLICATION_ID,
  NEXT_PUBLIC_DD_RUM_CLIENT_TOKEN,
  NEXT_PUBLIC_DD_VERSION
} from '@utils/constants';
import { buildDatadogUserTrackingObject } from './utils/buildUserTrackingObject';

class DatadogService {
  static initialize = () => {
    if (!NEXT_PUBLIC_DD_RUM_CLIENT_TOKEN) return;
    if (!NEXT_PUBLIC_DD_RUM_APPLICATION_ID) return;

    DatadogService.connect();
  }

  static connect = () => {

    datadogRum.init({
      clientToken: NEXT_PUBLIC_DD_RUM_CLIENT_TOKEN,
      applicationId: NEXT_PUBLIC_DD_RUM_APPLICATION_ID,
      version: NEXT_PUBLIC_DD_VERSION,
      site: 'datadoghq.com',
      service: 'arkestro-client',
      env: NEXT_PUBLIC_ARKESTRO_ENV,
      sessionSampleRate: 100, // sampleRate (v4) sessionSampleRate (v5)
      sessionReplaySampleRate: 100,
      trackUserInteractions: true, // trackInteractions (v4) trackUserInteractions (v5)
      defaultPrivacyLevel: 'allow',
      trackResources: true,
      trackLongTasks: true,
      silentMultipleInit: true,
      allowedTracingUrls: [/^https:\/\/.*\.arkestro(-[a-zA-Z]*)?\.com$/], // allowedTracingOrigins (v4) allowedTracingUrls (v5)
      enableExperimentalFeatures: ['feature_flags']
    });
  }

  static configureUser = user => {
    if (!user) return;

    datadogRum.setUser(buildDatadogUserTrackingObject(user));
    datadogRum.startSessionReplayRecording();
  }
}

export default DatadogService;
