const CHANNELS_LIMIT = 30;
const MAX_CHAT_API_REQUEST_LIMIT = 100;

const isUnread = (channel, user) => {
  // case where there is no message on the channel
  if (!channel.data.last_message_at)
    return false;

  // case where user has never read the channel
  if ( !channel.state.read[ user.id ])
    return true;

  const lastMessageTime = new Date(channel.data.last_message_at);
  const lastReadTime = new Date(channel.state.read[ user.id ].last_read);

  const result1 =  lastMessageTime > lastReadTime;
  const result2 = channel.countUnread() > 0;

  // see https://getstream.io/chat/docs/#unread_channel
  return result1 || result2;
};

/**
 * Helper async method to gather only unread channels possibly matching a filter.
 * @param {Object} client stream client object
 * @param {*} filter Plain object of the form { field: {query}|value }, ie { id: { $in: [ids] }}, or { adHoc: true }
 */
async function getUnreadChannels (client, filter) {
  const filterUnread = { ...filter, has_unread: true };
  const sort = [{ unread_count: -1 }, { last_message_at: -1 }];

  let nextChannels = await client.queryChannels(filterUnread, sort, {
    limit: CHANNELS_LIMIT,
    presence: true,
    watch: true
  });

  let channels = [...nextChannels];

  let iteration = 1;

  // i dont think we can refactor as suggested because we dont know the number of loops in advance
  // https://eslint.org/docs/rules/no-await-in-loop
  while (nextChannels.length > 0 && iteration < MAX_CHAT_API_REQUEST_LIMIT) {
    nextChannels = await client.queryChannels(filterUnread, sort, {
      limit: CHANNELS_LIMIT,
      offset: iteration * CHANNELS_LIMIT,
      watch: true
    });
    iteration += 1;
    channels = [...channels, ...nextChannels];
  }

  return channels;
}

export const channelIdWithoutType = cid => (
  cid
    .replace('messaging:', '')
    .replace('bid-request-announcements:', '')
);

// Note almost a dup of above function. Work on removing above function entirely and use this.
// Requires additional logic change in other components to remove above function.
export async function getChannelIdsWithUnread (streamClient, user) {
  // We could add a filter here on requestGroup property so limit the number of channels we have to query through
  // can use the $in property on requestGroup, on an array of all the `${subdomain}-${requestId}` keys
  // (given our list of requests)

  const vendorOrgId = user.vendor_profile?.vendor_organization_id;
  const filter = user.is_deeplink
    ? {
      $and: [
        {
          id: {
            $in: [
              `bidops-requestannouncements-${user.deeplink_contextable_id}`,
              `${user.deeplink_contextable_id}-vgroup-${vendorOrgId}`
            ]
          }
        },
        { members: { $in: [`${user.id}`] } }
      ]
    }
    : { members: { $in: [`${user.id}`] } };

  const unreadChannels = await getUnreadChannels(streamClient, filter);
  const { totalUnreadMessagesCount, unreadChannelMapFromFetch } = unreadChannels.reduce(
    (acc, c) => {
      const unreadCountForChannel = c.countUnread();
      acc.totalUnreadMessagesCount += unreadCountForChannel;
      acc.unreadChannelMapFromFetch[c.id] = unreadCountForChannel;
      return acc;
    },
    { totalUnreadMessagesCount: 0, unreadChannelMapFromFetch: {} }
  );

  return { totalUnreadMessagesCount, unreadChannelMapFromFetch };
}

export const channelTypeFromCID = cid => {
  let type = 'internal';

  if (cid.indexOf('-requestannouncements-') > -1) type = 'announcements';
  if (cid.indexOf('-adhoc-') > -1) type = 'adhoc';
  if (cid.indexOf('-vgroup-') > -1) type = 'vendor';

  return type;
};

// This method depends on specific naming in stream channel setup
export const doesChannelIdMatchRequest = (cid, request) => {
  if (!request || !request.id) return false;

  const cidArr = cid.split('-');
  const type = channelTypeFromCID(cid);
  const idPositions = {
    announcements: 2,
    internal: 1,
    adhoc: 2, // not a request id, but a UUID
    vendor: 0
  };

  const idPosition = idPositions[type];

  // channel ids have "messaging:" prepended
  return cidArr[idPosition].replace('messaging:', '') === request.id.toString();
};

/**
 * Asynchronous get call using complete message list
 * @param channel {Object} StreamChat channel to query with
 * @returns {Promise<*>} Promise which will contain the un-watched channel object
 */
export async function getCompleteListOfChannelMessages (channel) {
  const data = {};
  const messages = {
    limit: 10000, // Required to be greater than 0, figure 10k magic number if probably fine
    state: false
  };

  return channel.query({ data, messages });
}

/**
 * Simple async wrapper for querying with just filter
 * @param client {Object} Stream client object with connection info embedded
 * @param filter {{}} Plain object of the form { field: {query}|value }, ie { id: { $in: [ids] }}, or { adHoc: true }
 * @returns {Promise<Channel[]>} List of channels matching
 */
export async function getChannels (client, filter) {
  return client.queryChannels(filter, {}, {});
}
